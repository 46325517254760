* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'JetBrains Mono', monospace;
  font-size: 13px;
  text-align: center;
  color: #403c37;
  overflow: hidden;
}

body {
  background: #ebeae3;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
}

p {
  text-transform: uppercase;
}

a {
  color: #403c37;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 1px;
  /* animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; */
}

a:hover {
  text-decoration: underline;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%); 
  -ms-transform: translate(-50%, -50%); 
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  width: 100%;
  padding: 0 40px;
}

    .logo {
        width: 100%;
        max-width: 340px;
        display: block;
        margin: 0 auto 25px auto;
        /* animation: fadeInAnimation ease 5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; */
    }

    .email {
        margin-top: 15px;
        width: 100%;
        max-width: 40px;
    }

#leva__root {
  display: none;
}


.footer {
  position: absolute;
  width: 100px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  bottom: 20px;
  
}

.spotify {
  max-width: 40px;
  text-align: center;
}

.client {
  margin-block-start: 0px !important;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.footer:hover .client {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .client {
    visibility: visible;
    opacity: 1;
  }
}